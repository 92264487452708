import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import ellipse from "../Ellipse.png";
import Modal from "react-bootstrap/Modal";
import Rating from "../components/Rating";
import { siteConstants } from "../constants";
import { getCookie } from "../services/getCsrf";
import emptyCourse from "../empty-webinar.png";
import ReactTooltip from "react-tooltip";
import { ProgressBar } from "react-bootstrap";
import CourseRenewal from "../../src/images/course_renewal.png";
import { fetchDataWithCsrf } from "../services/fetchDataWithCsrf";

export default function UserCourse(props) {
  const history = useHistory();

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const [show, setShow] = useState(false);
  const [currentCourseObj, setCurrentCourseObj] = useState(null);
  const [requestForExtensionModal, setRequestForExtensionModal] =
    useState(false);
  const [requestForPaidExtensionModal, setRequestForPaidExtensionModal] =
    useState(false);
  const [currentRequestCourseObj, setCurrentRequestCourseObj] = useState(null);

  const [extensionDay, setExtensionDay] = useState(90);
  const [extensionReason, setExtensionReason] = useState("");
  const [extensionPaidReason, setExtensionPaidReason] = useState("");
  const [submitExtensionStep, setSubmitExtensionStep] = useState(1);
  const [submitPaidExtensionStep, setSubmitPaidExtensionStep] = useState(1);

  const handleClose = () => {
    setShow(false);
  };
  const handleCloseRequestForExtensionModal = () => {
    setRequestForExtensionModal(false);
    setSubmitExtensionStep(1);
  };
  const handleCloseRequestForPaidExtensionModal = () => {
    setRequestForPaidExtensionModal(false);
    setSubmitPaidExtensionStep(1);
  };

  const handleOpenRating = (data) => {
    //props.setcurrentCourse(data)
    setCurrentCourseObj(data);
    setShow(true);
  };
  const handleOpenRequestForExtension = (data) => {
    setCurrentRequestCourseObj(data);
    setRequestForExtensionModal(true);
  };
  const handleOpenRequestForPaidExtension = (data) => {
    setCurrentRequestCourseObj(data);
    setRequestForPaidExtensionModal(true);
  };
  const handleChangeDayOfExtension = (e) => {
    setExtensionDay(e.target.value);
  };
  const handleChangeReasonOfExtension = (e) => {
    setExtensionReason(e.target.value);
  };
  const handleChangeReasonOfPaidExtension = (e) => {
    setExtensionPaidReason(e.target.value);
  };
  const sendRequestExtension = async (e) => {
    e.preventDefault();
    let postdata = {
      XRequestedWith: "XMLHttp",
      inv_course_id: currentRequestCourseObj.id,
      no_of_days: extensionDay,
      reason: extensionReason,
    };

    let d = await fetchPostData("courses/sendRequestExtension", postdata);
    setSubmitExtensionStep(2);
  };
  const [clickLoading, setClickLoading] = useState(false);
  const sendRequestPaidExtension = async (e) => {
    setClickLoading(true);

    e.preventDefault();
    e.stopPropagation();

    window.location.href =
      process.env.REACT_APP_NEXT_HOME_URL +
      "cartlive/" +
      currentRequestCourseObj.slug+"/1";
    setClickLoading(false);
    return false;
  };

  const calculateTimimg = (d) => {
    let months = 0,
      years = 0,
      days = 0,
      weeks = 0;
    while (d) {
      if (d >= 365) {
        years++;
        d -= 365;
      } else if (d >= 30) {
        months++;
        d -= 30;
      } else if (d >= 7) {
        weeks++;
        d -= 7;
      } else {
        days++;
        d--;
      }
    }
    var str = "";
    if (years > 0 && years === 1) {
      str = str + years + " year";
    } else if (years > 1) {
      str = str + years + " years";
    }
    if (months > 0 && months === 1) {
      str = str + months + "month";
    } else if (months > 1) {
      str = str + months + "months";
    }
    return str;
  };

  const fetchPostData = async (url, data) => {
    let r1 = await fetchDataWithCsrf(data, url);
    return r1;
  };
  let h = window.screen.availHeight;

  const courseClick = (course) => {
    if (course.datediff > 0) {
      history.push(`/learn/${course.course.id}`);
    } else if (course.course.is_paid_extension) {
      handleOpenRequestForPaidExtension(course.course);
    } else {
      handleOpenRequestForExtension(course);
    }
  };

  let userCourse = props.feedData.map(function (feedData, index) {
    if (feedData.is_recent_course) {
      return (
        <div className="col-lg-4"  key={index}>
          <div className="card custom-learning-card">
            <div
              className="learning-card-left-img" style={{ cursor: "pointer" }}
              onClick={() => courseClick(feedData)}
            >
              <img
                lazy
                alt={feedData.course.name}
                src={feedData.course.photo}
                className="img-fluid"
              />
            </div>
            <div
              className="learning-card-right-containt"
              style={{ cursor: "pointer" }}
              onClick={() => courseClick(feedData)}
            >
              <img alt="" src={ellipse} className="ellipse" />
              <div className="row">
                <div className="col-2 pl-0">
                  <p className="play-btn-for-all-courses mb-0">
                    <i class="fa fa-play-circle" aria-hidden="true"></i>
                  </p>
                </div>
                <div className="col-10 pr-0">
                  <small style={{ fontSize: "9px", color: "#9c9c9c" }}>
                    Continue Learning
                  </small>
                  <div className="custom-progress-bar-course">
                    <ProgressBar
                      variant="success"
                      now={feedData.completed_units}
                    />
                    <span
                      className="percentage-of-complete"
                      data-tip={`You have  ${
                        100 - feedData.completed_units
                      }% more to complete`}
                    >
                      {feedData.completed_units}%
                    </span>
                    {feedData.datediff <= 365 && (
                      <span
                        className="dayes-left-expired"
                        data-tip={
                          feedData.datediff > 0
                            ? "Finish this course inside " +
                              feedData.datediff +
                              " days"
                            : "Course expired"
                        }
                      >
                        {feedData.datediff > 0
                          ? feedData.datediff + " days left"
                          : "0 day left"}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <p className="mb-0 mt-1" style={{ cursor: "pointer" }} onClick={() => courseClick(feedData)}>
                <span className="learning-name mb-0">
                  {feedData.course.name}
                </span>
              </p>

              <div className="row text-right">
                <div className="col-12"></div>
              </div>
            </div>
            {feedData.datediff >0 && (
            <p className="unit-time-card">
              <a
                onClick={() => courseClick(feedData)}
                href="javascript:void(0)"
                data-tip={"Continue Learning"}
              >
                {"Continue Learning"}
              </a>
            </p>
            )}
            <p
              className={`unit-time-card ${
                feedData.datediff <= 0 || feedData.course.is_paid_extension
                  ? "extension_free"
                  : ""
              }`}
            >
              {feedData.datediff <= 0 && !feedData.course.is_paid_extension && (
                <a href="javascript:void(0)" data-tip={"Apply For Extension"}
                onClick={() => handleOpenRequestForExtension(feedData)}>
                  {"Apply For Extension"}
                </a>
              )}
              {feedData.course.is_paid_extension && (
                <a
                  href="javascript:void(0)"
                  onClick={() =>
                    handleOpenRequestForPaidExtension(feedData.course)
                  }
                  data-tip="Renew Course Access"
                >
                  {"Renew Course Access"}
                </a>
              )}
            </p>
          </div>
        </div>
      );
    }
  }, this);

  let userCompletedCourse = props.feedData.map(function (feedData, index) {
    if (!feedData.is_recent_course) {
      return (
        <div className="col-lg-4 " key={index} style={{ cursor: "pointer" }}>
          <div className="card custom-learning-card">
            <div className="learning-card-left-img">
              <img lazy alt="" src={feedData.course.photo} />
            </div>
            <div className="learning-card-right-containt">
              <img src={ellipse} alt="" className="ellipse" />

              <p className="mb-0">
                <span className="learning-name">{feedData.course.name}</span>
              </p>

              <div className="row">
                <div className="col-lg-5 col-12">
                  <span
                    className="webinar-date"
                    onClick={() => handleOpenRating(feedData.course)}
                    style={{ cursor: "pointer" }}
                    data-tip="click to submit your review"
                  >
                    <i class="fa fa-star" aria-hidden="true"></i>Review
                  </span>
                </div>
                <div className="col-lg-7 col-12">
                  <span className="webinar-time">
                    <i class="fa fa-clock-o" aria-hidden="true"></i>{" "}
                    {feedData.datediff > 0
                      ? feedData.datediff + " days left"
                      : "0 day left"}
                  </span>
                </div>
              </div>
              <p
                className={`unit-time-card ${
                  feedData.datediff <= 0 || feedData.course.is_paid_extension ? "extension" : ""
                }`}
              >
                {feedData.datediff > 0 && (
                  <Link to={`/learn/${feedData.course.id}`}>Learn Again</Link>
                )}
                </p>
                <p
                className={`unit-time-card ${
                  feedData.datediff <= 0 || feedData.course.is_paid_extension ? "extension_free" : ""
                }`}
              >
                {feedData.datediff <= 0 &&
                  !feedData.course.is_paid_extension && (
                    <a
                      href="javascript:void(0)"
                      onClick={() => handleOpenRequestForExtension(feedData)}
                      data-tip="Apply for Extension"
                    >
                      Apply For Extension
                    </a>
                  )}
                {feedData.course.is_paid_extension && (
                  <a
                    href="javascript:void(0)"
                    onClick={() =>
                      handleOpenRequestForPaidExtension(feedData.course)
                    }
                    data-tip="Renew Course Access"
                  >
                    Renew Course Access
                  </a>
                )}
              </p>
            </div>
          </div>
        </div>
      );
    }
  }, this);

  return (
    <div className="row">
      {props.feedData.length > 0 &&
        props.feedData.findIndex((item) => item.is_recent_course === true) >
          -1 && (
          <div className="col-lg-12 mb-4">
            <div className="section-header">
              <p>
                Currently <span className="blue-text">Learning</span>
              </p>
            </div>
            <div className="row">
              {userCourse}

              <div className="col-lg-12">
                <p className="text-center"></p>
              </div>
            </div>
          </div>
        )}

      {props.feedData.length > 0 &&
        props.feedData.findIndex((item) => item.is_recent_course === false) >
          -1 && (
          <div className="col-lg-12">
            <div className="section-header">
              <p>
                {" "}
                Completed <span className="blue-text">Courses</span>
              </p>
            </div>
            <div className="row">
              {userCompletedCourse}

              <div className="col-lg-12">
                <p className="text-center"></p>
              </div>
            </div>
          </div>
        )}
      {props.feedData.length === 0 && (
        <div className="col-lg-12 mb-4">
          <div className="empty-section-top-box" style={{ height: h / 2 }}>
            <div
              className="empty-section-inner-box"
              style={{ marginTop: h / 4 - 25 }}
            >
              <img
                src={emptyCourse}
                alt="logo"
                className="img-fluid empty-page-img"
              />
              <p className="empty-page-text">
                Add a course of your choice to start your learning today!
              </p>
              <a
                href={`${process.env.REACT_APP_NEXT_HOME_URL + "courses"}`}
                target="_blank"
                style={{ color: "white" }}
                className="elm-primary-btn"
              >
                View courses
              </a>
            </div>
          </div>
        </div>
      )}
      <ReactTooltip className="mytooltip" />
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        className="custom-modal"
      >
        <Modal.Header closeButton>
          {currentCourseObj && (
            <Modal.Title>{currentCourseObj.name}</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          {currentCourseObj && (
            <Rating
              item_id={currentCourseObj.id}
              item_type={1}
              modalClosed={handleClose}
            />
          )}
        </Modal.Body>
      </Modal>
      <Modal
        show={requestForPaidExtensionModal}
        onHide={handleCloseRequestForPaidExtensionModal}
        animation={false}
        className="custom-modal"
      >
        <Modal.Header closeButton>
          {currentRequestCourseObj && (
            <Modal.Title>Course Access Renewal</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          {currentRequestCourseObj && (
            <div>
              {submitPaidExtensionStep === 2 && (
                <div>
                  <div className="popup_tick_bg">
                    <img
                      src="https://elm-email-templates.s3.ap-southeast-1.amazonaws.com/email-templates/img/2023/request_a_call_bg.png"
                      className="img-fluid"
                    />
                    <img
                      src="https://elm-email-templates.s3.ap-southeast-1.amazonaws.com/email-templates/img/2023/request_a_call_tick.gif"
                      className="submit_popup_tick"
                    />
                  </div>
                  <p className="submit_header">
                    Your request has been <span>Submitted!</span>
                  </p>
                </div>
              )}
              {submitPaidExtensionStep === 1 && (
                <div className="mb-3">
                  <div className="text-center">
                    <img src={CourseRenewal} className="img-fluid" />
                    <p className="mt-3">
                      To renew your course access for{" "}
                      <span style={{ fontWeight: "600", color: "#12274B" }}>
                        {calculateTimimg(currentRequestCourseObj.duration)}
                      </span>
                      , <br />
                      you will be redirected to the payment gateway <br />
                      to complete the checkout.
                    </p>
                  </div>

                  <form
                    method="post"
                    acceptCharset="utf-8"
                    onSubmit={!clickLoading && sendRequestPaidExtension}
                  >
                    <div className="text-center mt-4">
                      <button
                        className="elm-btn elm-btn-primary-blue btn btn-primary request_submit"
                        defaultValue="Request extension"
                        type="submit"
                      >
                        {!clickLoading ? (
                          <span>redirect me to cart</span>
                        ) : (
                          <span>
                            <i className="fa fa-circle-o-notch fa-spin"></i>
                            Submitting..
                          </span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        show={requestForExtensionModal}
        onHide={handleCloseRequestForExtensionModal}
        animation={false}
        className="custom-modal"
      >
        <Modal.Header closeButton>
          {currentRequestCourseObj && (
            <Modal.Title>Extend your Course Access</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          {currentRequestCourseObj && (
            <div>
              {submitExtensionStep === 2 && (
                <div>
                  <div className="popup_tick_bg">
                    <img
                      src="https://elm-email-templates.s3.ap-southeast-1.amazonaws.com/email-templates/img/2023/request_a_call_bg.png"
                      className="img-fluid"
                    />
                    <img
                      src="https://elm-email-templates.s3.ap-southeast-1.amazonaws.com/email-templates/img/2023/request_a_call_tick.gif"
                      className="submit_popup_tick"
                    />
                  </div>
                  <p className="submit_header">
                    Your request has been <span>Submitted!</span>
                  </p>
                </div>
              )}
              {submitExtensionStep === 1 && (
                <div>
                  
                  <form
                    method="post"
                    acceptCharset="utf-8"
                    onSubmit={sendRequestExtension}
                  >
                    
                    <p className="mt-3 mt-4 text-center">
                      Please specify your reason for extending course validity
                    </p>
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        name="reason"
                        rows={4}
                        placeholder="Type here..."
                        defaultValue={""}
                        onChange={handleChangeReasonOfExtension}
                        required
                      />
                    </div>

                    <div className="text-center mt-4 mb-3">
                      <input
                        className="elm-btn elm-btn-primary-blue btn btn-primary request_submit"
                        defaultValue="Request extension"
                        type="submit"
                        value="Submit Request"
                      />
                    </div>
                  </form>
                </div>
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
